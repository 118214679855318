import Vue from 'vue'
import App from './App.vue'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from "echarts";
// import 'reset.css'
// import './assets/css/font.css'
// 将自动注册所有组件为全局组件
// import dataV from '@jiaminghi/data-view'
import router from './router'
// import myTitle from './components/my-title.vue'
// import './utils/flexilb'

// Vue.use(ElementUI);
// Vue.use(dataV)
// Vue.component('my-title',myTitle)
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
new Vue({
	// el: '#app',
	router,
	render: h => h(App),
}).$mount('#app')