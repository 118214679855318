<template>
  <div class="data-box">
      <router-view />
  </div>
</template>


<script>


export default {

}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.data-box{
  height: 100vh;
}


</style>