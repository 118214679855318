import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const router = new VueRouter({
	
  routes: [
    {
        path:'/',
        component:()=>import('../views/home/index.vue')
    },
    // {
    //   path:'/organization',
    //   component:()=>import('../views/organization/index.vue')
    // },
    // {
    //   path:'/css',
    //   component:()=>import('../views/index.vue')
    // },
    // {
    //   path:'/satrt',
    //   component:()=>import('../views/demo/index.vue')
    // }
  ],
  mode: 'hash',
  base: process.env.BASE_URL,
});

export default router;